// Import mixins
@import 'components/mixins.scss';

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-progress {
    &-inner {
      background: $dark-gray-4;
    }
  }
}

// DEFAULT THEME STYLES
[data-kit-theme='default'] {
  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: $kairos-dark;
  }

  .ant-progress-status-success
    .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: $kairos-dark;
  }
}
