// Import mixins
@import 'components/mixins.scss';

[data-kit-theme='default'] {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
    line-height: 1;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-collapse {
    background: transparent;
    &-header {
      background: $dark-gray-6;
      color: $dark-gray-1 !important;
    }
  }
}
