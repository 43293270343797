@import 'components/mixins.scss';

[data-kit-theme='default'] {
  @media only screen and (min-width: 768px) {
    .container {
      border-top-left-radius: 30px;
    }
  }

  .container {
    background: #fff;
  }
}

// dark theme
[data-kit-theme='dark'] {
  .container {
    background: white;
  }
}
