// Import mixins
@import 'components/mixins.scss';

[data-kit-theme='default'] {
  .ant-btn {
    border-radius: 5px;

    &:hover,
    &:active,
    &:focus {
      border-color: darken($kairos-light, 5%);
      color: darken($kairos-light, 5%);
    }
    &-primary {
      background: $primary;
      border-color: $primary;

      &:hover,
      &:active,
      &:focus {
        background: darken($kairos-light, 5%);
        border-color: darken($kairos-light, 5%);
        color: $white;
      }
    }
    &-default {
      border-color: $primary;
      color: $primary !important;
    }
    &-link {
      color: $primary !important;

      &:hover,
      &:active,
      &:focus {
        border: transparent;
      }
    }
    &-dangerous {
      color: $white;
      &:hover,
      &:active,
      &:focus {
        background: lighten($danger, 5%);
        border-color: lighten($danger, 5%);
        color: $white;
      }
    }
    &-primary,
    &-dangerous {
      &[disabled],
      &[disabled]:hover {
        background: $gray-2;
        border-color: $gray-2;
      }
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-btn {
    &-primary {
      &:hover,
      &:active,
      &:focus {
        background: lighten($kairos-light, 0%) !important;
        border-color: lighten($kairos-light, 0%) !important;
        color: $white;
      }
    }
    &-dangerous {
      &:hover,
      &:active,
      &:focus {
        background: lighten($danger, 10%);
        border-color: lighten($danger, 10%);
        color: $white;
      }
    }
    &-primary,
    &-dangerous {
      &[disabled],
      &[disabled]:hover {
        background: #1d1b2c !important;
        border-color: #232135 !important;
      }
    }
  }
}
