// Import mixins
@import 'components/mixins.scss';

[data-kit-theme='default'] {
  .ant-tag {
    border: none !important;

    &.ant-tag-success {
      background-color: #f0ffe0 !important;
    }

    &.ant-tag-warning {
      background-color: #fff5e0 !important;
    }

    &.ant-tag-processing {
      background-color: #e0f5ff !important;
    }

    &.ant-tag-error {
      background-color: #ffe2e0 !important;
    }

    &.ant-tag-default {
      background-color: #f0f0f0 !important;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-tag {
    border-color: transparent;
  }
}
