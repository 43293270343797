/* line 4, ../sass/_offline-theme-base.sass */
/* line 7, ../sass/_offline-theme-base.sass */
/* line 17, ../sass/_offline-theme-base.sass */
/* line 20, ../sass/_offline-theme-base.sass */
/* line 24, ../sass/_offline-theme-base.sass */
/* line 29, ../sass/_offline-theme-base.sass */
/* line 32, ../sass/_offline-theme-base.sass */
/* line 37, ../sass/_offline-theme-base.sass */
/* line 42, ../sass/_offline-theme-base.sass */
@-webkit-keyframes "offline-dropin" {
/* line 40, ../sass/_keyframes.sass */
	0% {
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		opacity: 0;
	}
/* line 43, ../sass/_keyframes.sass */
	1% {
		transform: translateY(-800px);
		-webkit-transform: translateY(-800px);
		-moz-transform: translateY(-800px);
		-ms-transform: translateY(-800px);
		-o-transform: translateY(-800px);
		opacity: 0;
	}
/* line 48, ../sass/_keyframes.sass */
	2% {
		transform: translateY(-800px);
		-webkit-transform: translateY(-800px);
		-moz-transform: translateY(-800px);
		-ms-transform: translateY(-800px);
		-o-transform: translateY(-800px);
		opacity: 1;
	}
/* line 51, ../sass/_keyframes.sass */
	100% {
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		opacity: 1;
	}
}
@-moz-keyframes "offline-dropin" {
/* line 40, ../sass/_keyframes.sass */
	0% {
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		opacity: 0;
	}
/* line 43, ../sass/_keyframes.sass */
	1% {
		transform: translateY(-800px);
		-webkit-transform: translateY(-800px);
		-moz-transform: translateY(-800px);
		-ms-transform: translateY(-800px);
		-o-transform: translateY(-800px);
		opacity: 0;
	}
/* line 48, ../sass/_keyframes.sass */
	2% {
		transform: translateY(-800px);
		-webkit-transform: translateY(-800px);
		-moz-transform: translateY(-800px);
		-ms-transform: translateY(-800px);
		-o-transform: translateY(-800px);
		opacity: 1;
	}
/* line 51, ../sass/_keyframes.sass */
	100% {
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		opacity: 1;
	}
}
@-ms-keyframes "offline-dropin" {
/* line 40, ../sass/_keyframes.sass */
	0% {
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		opacity: 0;
	}
/* line 43, ../sass/_keyframes.sass */
	1% {
		transform: translateY(-800px);
		-webkit-transform: translateY(-800px);
		-moz-transform: translateY(-800px);
		-ms-transform: translateY(-800px);
		-o-transform: translateY(-800px);
		opacity: 0;
	}
/* line 48, ../sass/_keyframes.sass */
	2% {
		transform: translateY(-800px);
		-webkit-transform: translateY(-800px);
		-moz-transform: translateY(-800px);
		-ms-transform: translateY(-800px);
		-o-transform: translateY(-800px);
		opacity: 1;
	}
/* line 51, ../sass/_keyframes.sass */
	100% {
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		opacity: 1;
	}
}
@-o-keyframes "offline-dropin" {
/* line 40, ../sass/_keyframes.sass */
	0% {
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		opacity: 0;
	}
/* line 43, ../sass/_keyframes.sass */
	1% {
		transform: translateY(-800px);
		-webkit-transform: translateY(-800px);
		-moz-transform: translateY(-800px);
		-ms-transform: translateY(-800px);
		-o-transform: translateY(-800px);
		opacity: 0;
	}
/* line 48, ../sass/_keyframes.sass */
	2% {
		transform: translateY(-800px);
		-webkit-transform: translateY(-800px);
		-moz-transform: translateY(-800px);
		-ms-transform: translateY(-800px);
		-o-transform: translateY(-800px);
		opacity: 1;
	}
/* line 51, ../sass/_keyframes.sass */
	100% {
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		opacity: 1;
	}
}
@keyframes "offline-dropin" {
/* line 40, ../sass/_keyframes.sass */
	0% {
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		opacity: 0;
	}
/* line 43, ../sass/_keyframes.sass */
	1% {
		transform: translateY(-800px);
		-webkit-transform: translateY(-800px);
		-moz-transform: translateY(-800px);
		-ms-transform: translateY(-800px);
		-o-transform: translateY(-800px);
		opacity: 0;
	}
/* line 48, ../sass/_keyframes.sass */
	2% {
		transform: translateY(-800px);
		-webkit-transform: translateY(-800px);
		-moz-transform: translateY(-800px);
		-ms-transform: translateY(-800px);
		-o-transform: translateY(-800px);
		opacity: 1;
	}
/* line 51, ../sass/_keyframes.sass */
	100% {
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		opacity: 1;
	}
}
@-webkit-keyframes "offline-dropout" {
/* line 57, ../sass/_keyframes.sass */
	0% {
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
	}
/* line 59, ../sass/_keyframes.sass */
	100% {
		transform: translateY(-800px);
		-webkit-transform: translateY(-800px);
		-moz-transform: translateY(-800px);
		-ms-transform: translateY(-800px);
		-o-transform: translateY(-800px);
	}
}
@-moz-keyframes "offline-dropout" {
/* line 57, ../sass/_keyframes.sass */
	0% {
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
	}
/* line 59, ../sass/_keyframes.sass */
	100% {
		transform: translateY(-800px);
		-webkit-transform: translateY(-800px);
		-moz-transform: translateY(-800px);
		-ms-transform: translateY(-800px);
		-o-transform: translateY(-800px);
	}
}
@-ms-keyframes "offline-dropout" {
/* line 57, ../sass/_keyframes.sass */
	0% {
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
	}
/* line 59, ../sass/_keyframes.sass */
	100% {
		transform: translateY(-800px);
		-webkit-transform: translateY(-800px);
		-moz-transform: translateY(-800px);
		-ms-transform: translateY(-800px);
		-o-transform: translateY(-800px);
	}
}
@-o-keyframes "offline-dropout" {
/* line 57, ../sass/_keyframes.sass */
	0% {
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
	}
/* line 59, ../sass/_keyframes.sass */
	100% {
		transform: translateY(-800px);
		-webkit-transform: translateY(-800px);
		-moz-transform: translateY(-800px);
		-ms-transform: translateY(-800px);
		-o-transform: translateY(-800px);
	}
}
@keyframes "offline-dropout" {
/* line 57, ../sass/_keyframes.sass */
	0% {
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
	}
/* line 59, ../sass/_keyframes.sass */
	100% {
		transform: translateY(-800px);
		-webkit-transform: translateY(-800px);
		-moz-transform: translateY(-800px);
		-ms-transform: translateY(-800px);
		-o-transform: translateY(-800px);
	}
}
@-webkit-keyframes "offline-rotation" {
/* line 64, ../sass/_keyframes.sass */
	0% {
		transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
	}
/* line 66, ../sass/_keyframes.sass */
	100% {
		transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		-moz-transform: rotate(359deg);
		-ms-transform: rotate(359deg);
		-o-transform: rotate(359deg);
	}
}
@-moz-keyframes "offline-rotation" {
/* line 64, ../sass/_keyframes.sass */
	0% {
		transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
	}
/* line 66, ../sass/_keyframes.sass */
	100% {
		transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		-moz-transform: rotate(359deg);
		-ms-transform: rotate(359deg);
		-o-transform: rotate(359deg);
	}
}
@-ms-keyframes "offline-rotation" {
/* line 64, ../sass/_keyframes.sass */
	0% {
		transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
	}
/* line 66, ../sass/_keyframes.sass */
	100% {
		transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		-moz-transform: rotate(359deg);
		-ms-transform: rotate(359deg);
		-o-transform: rotate(359deg);
	}
}
@-o-keyframes "offline-rotation" {
/* line 64, ../sass/_keyframes.sass */
	0% {
		transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
	}
/* line 66, ../sass/_keyframes.sass */
	100% {
		transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		-moz-transform: rotate(359deg);
		-ms-transform: rotate(359deg);
		-o-transform: rotate(359deg);
	}
}
@keyframes "offline-rotation" {
/* line 64, ../sass/_keyframes.sass */
	0% {
		transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
	}
/* line 66, ../sass/_keyframes.sass */
	100% {
		transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		-moz-transform: rotate(359deg);
		-ms-transform: rotate(359deg);
		-o-transform: rotate(359deg);
	}
}
/* line 16, ../sass/offline-theme-dark.sass */
/* line 16, ../sass/offline-theme-dark.sass */
/* line 30, ../sass/offline-theme-dark.sass */
/* line 33, ../sass/offline-theme-dark.sass */
/* line 36, ../sass/offline-theme-dark.sass */
/* line 48, ../sass/offline-theme-dark.sass */
/* line 61, ../sass/offline-theme-dark.sass */
/* line 64, ../sass/offline-theme-dark.sass */
/* line 67, ../sass/offline-theme-dark.sass */
/* line 70, ../sass/offline-theme-dark.sass */
/* line 73, ../sass/offline-theme-dark.sass */
/* line 76, ../sass/offline-theme-dark.sass */
/* line 79, ../sass/offline-theme-dark.sass */
/* line 96, ../sass/offline-theme-dark.sass */
/* line 99, ../sass/offline-theme-dark.sass */
/* line 6, ../sass/_content.sass */
/* line 11, ../sass/_content.sass */
/* line 11, ../sass/_content.sass */
/* line 11, ../sass/_content.sass */
/* line 22, ../sass/_content.sass */
/* line 22, ../sass/_content.sass */
/* line 22, ../sass/_content.sass */
/* line 33, ../sass/_content.sass */
/* line 42, ../sass/_content.sass */
/* line 42, ../sass/_content.sass */
/* line 50, ../sass/_content.sass */
/* line 50, ../sass/_content.sass */
/* line 58, ../sass/_content.sass */
/* line 58, ../sass/_content.sass */
/* line 66, ../sass/_content.sass */
/* line 66, ../sass/_content.sass */
/* line 74, ../sass/_content.sass */
/* line 74, ../sass/_content.sass */
/* line 82, ../sass/_content.sass */
/* line 82, ../sass/_content.sass */
/* line 90, ../sass/_content.sass */
/* line 93, ../sass/_content.sass */
.offline-ui {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	display: none;
	position: fixed;
	background: white;
	z-index: 2000;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	-webkit-border-radius: 0 0 4px 4px;
	-moz-border-radius: 0 0 4px 4px;
	-ms-border-radius: 0 0 4px 4px;
	-o-border-radius: 0 0 4px 4px;
	border-radius: 0 0 4px 4px;
	font-family: 'Helvetica Neue', sans-serif;
	font-weight: 300;
	padding: 1em;
	width: 38em;
	max-width: 100%;
	background: black;
	color: #cccccc;
	overflow: hidden;
	* {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		&:before {
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
		}
		&:after {
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
		}
	}
	&:before {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	&:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	.offline-ui-content {
		&:before {
			display: inline;
			line-height: 1.25em;
		}
		padding-left: 2em;
		&:after {
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			-ms-border-radius: 50%;
			-o-border-radius: 50%;
			border-radius: 50%;
			content: ' ';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 1em;
			margin: auto;
			height: 1em;
			width: 1em;
		}
	}
	.offline-ui-retry {
		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;
		display: none;
		position: absolute;
		right: 3em;
		top: 0;
		bottom: 0;
		background: rgba(255, 255, 255, 0.2);
		text-decoration: none;
		color: inherit;
		line-height: 3.5em;
		height: 3.5em;
		margin: auto;
		padding: 0 1em;
		&:before {
			display: inline;
			content: 'Reconnect';
		}
	}
}
.offline-ui.offline-ui-up.offline-ui-up-5s {
	display: block;
}
.offline-ui.offline-ui-down {
	display: block;
	-webkit-animation: offline-dropin 0.5s;
	-moz-animation: offline-dropin 0.5s;
	-ms-animation: offline-dropin 0.5s;
	-o-animation: offline-dropin 0.5s;
	animation: offline-dropin 0.5s;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	.offline-ui-content {
		&:after {
			background: #e24949;
		}
		&:before {
			content: 'Your computer lost its internet connection.';
		}
	}
}
.offline-ui.offline-ui-down.offline-ui-waiting {
	.offline-ui-retry {
		display: block;
	}
	padding-right: 3em;
	padding-right: 11em;
	.offline-ui-content {
		&:after {
			background: #e24949;
		}
	}
	&:after {
		-webkit-animation: offline-rotation 0.7s linear infinite;
		-moz-animation: offline-rotation 0.7s linear infinite;
		-ms-animation: offline-rotation 0.7s linear infinite;
		-o-animation: offline-rotation 0.7s linear infinite;
		animation: offline-rotation 0.7s linear infinite;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
		border-radius: 50%;
		content: ' ';
		display: block;
		position: absolute;
		right: 1em;
		top: 0;
		bottom: 0;
		margin: auto;
		height: 1em;
		width: 1em;
		border: 2px solid transparent;
		border-top-color: rgba(255, 255, 255, 0.5);
		border-left-color: rgba(255, 255, 255, 0.5);
		opacity: 0.7;
	}
	.offline-ui-content[data-retry-in-unit='second'] {
		&:before {
			content: 'Connection lost. Reconnecting in 'attr(data-retry-in-value) ' seconds...';
		}
	}
	.offline-ui-content[data-retry-in-unit='second'][data-retry-in-value='1'] {
		&:before {
			content: 'Connection lost. Reconnecting in 'attr(data-retry-in-value) ' second...';
		}
	}
	.offline-ui-content[data-retry-in-unit='minute'] {
		&:before {
			content: 'Connection lost. Reconnecting in 'attr(data-retry-in-value) ' minutes...';
		}
	}
	.offline-ui-content[data-retry-in-unit='minute'][data-retry-in-value='1'] {
		&:before {
			content: 'Connection lost. Reconnecting in 'attr(data-retry-in-value) ' minute...';
		}
	}
	.offline-ui-content[data-retry-in-unit='hour'] {
		&:before {
			content: 'Connection lost. Reconnecting in 'attr(data-retry-in-value) ' hours...';
		}
	}
	.offline-ui-content[data-retry-in-unit='hour'][data-retry-in-value='1'] {
		&:before {
			content: 'Connection lost. Reconnecting in 'attr(data-retry-in-value) ' hour...';
		}
	}
}
.offline-ui.offline-ui-down.offline-ui-reconnect-failed-2s.offline-ui-waiting {
	.offline-ui-retry {
		display: none;
		display: none;
	}
}
.offline-ui.offline-ui-up {
	-webkit-animation: offline-dropout forwards 0.5s 2s;
	-moz-animation: offline-dropout forwards 0.5s 2s;
	-ms-animation: offline-dropout forwards 0.5s 2s;
	-o-animation: offline-dropout forwards 0.5s 2s;
	animation: offline-dropout forwards 0.5s 2s;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	.offline-ui-content {
		&:after {
			background: #80d580;
		}
		&:before {
			content: 'Your computer is connected to the internet.';
		}
	}
}
.offline-ui.offline-ui-down.offline-ui-connecting {
	padding-right: 3em;
	.offline-ui-content {
		&:after {
			background: #e24949;
		}
		&:before {
			content: 'Attempting to reconnect...';
		}
	}
	&:after {
		-webkit-animation: offline-rotation 0.7s linear infinite;
		-moz-animation: offline-rotation 0.7s linear infinite;
		-ms-animation: offline-rotation 0.7s linear infinite;
		-o-animation: offline-rotation 0.7s linear infinite;
		animation: offline-rotation 0.7s linear infinite;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
		border-radius: 50%;
		content: ' ';
		display: block;
		position: absolute;
		right: 1em;
		top: 0;
		bottom: 0;
		margin: auto;
		height: 1em;
		width: 1em;
		border: 2px solid transparent;
		border-top-color: rgba(255, 255, 255, 0.5);
		border-left-color: rgba(255, 255, 255, 0.5);
		opacity: 0.7;
	}
}
.offline-ui.offline-ui-down.offline-ui-waiting.offline-ui-reconnect-failed-2s {
	padding-right: 0;
}
.offline-ui.offline-ui-down.offline-ui-connecting-2s {
	.offline-ui-content {
		&:before {
			content: 'Attempting to reconnect...';
		}
	}
}
.offline-ui.offline-ui-down.offline-ui-reconnect-failed-2s {
	.offline-ui-content {
		&:before {
			content: 'Connection attempt failed.';
		}
	}
}
@media (max-width: 38em) {
	.offline-ui {
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		-ms-border-radius: 0;
		-o-border-radius: 0;
		border-radius: 0;
	}
}
@media (max-width: 1024px) {
	.offline-ui.offline-ui-up {
		.offline-ui-content {
			&:before {
				content: 'Your device is connected to the internet.';
			}
		}
	}
	.offline-ui.offline-ui-down {
		.offline-ui-content {
			&:before {
				content: 'Your device lost its internet connection.';
			}
		}
	}
}
@media (max-width: 568px) {
	.offline-ui.offline-ui-up {
		.offline-ui-content {
			&:before {
				content: 'Your device is connected.';
			}
		}
	}
	.offline-ui.offline-ui-down {
		.offline-ui-content {
			&:before {
				content: "Your device isn't connected.";
			}
		}
	}
	.offline-ui.offline-ui-down.offline-ui-waiting {
		.offline-ui-content[data-retry-in-unit='second'] {
			&:before {
				content: 'Reconnecting in 'attr(data-retry-in-value) 's...';
			}
		}
		.offline-ui-content[data-retry-in-unit='second'][data-retry-in-value='1'] {
			&:before {
				content: 'Reconnecting in 'attr(data-retry-in-value) 's...';
			}
		}
		.offline-ui-content[data-retry-in-unit='minute'] {
			&:before {
				content: 'Reconnecting in 'attr(data-retry-in-value) 'm...';
			}
		}
		.offline-ui-content[data-retry-in-unit='minute'][data-retry-in-value='1'] {
			&:before {
				content: 'Reconnecting in 'attr(data-retry-in-value) 'm...';
			}
		}
		.offline-ui-content[data-retry-in-unit='hour'] {
			&:before {
				content: 'Reconnecting in 'attr(data-retry-in-value) 'h...';
			}
		}
		.offline-ui-content[data-retry-in-unit='hour'][data-retry-in-value='1'] {
			&:before {
				content: 'Reconnecting in 'attr(data-retry-in-value) 'h...';
			}
		}
	}
}
