// Import mixins
@import 'components/mixins.scss';

// DARK THEME STYLES
[data-kit-theme='default'] {
  .ant-table {
    &-tbody > tr:last-child > td {
      border: none;
    }

    &-thead > tr > th,
    &-small &-thead > tr > th {
      background-color: unset !important;
      border-bottom: none;

      &::before {
        display: none;
      }
    }

    // .ant-table-wrapper {
    //   .ant-table-thead {
    //     > tr > th {
    //       background: none;

    //       &::before {
    //         display: none;
    //       }
    //     }
    //   }
    // }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-table-column-has-sorters {
    &:hover {
      background: $dark-gray-5 !important;
    }
  }
  .ant-table-column-sort {
    background: $dark-gray-5 !important;
  }
}
