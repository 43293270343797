// Import mixins
@import 'components/mixins.scss';

[data-kit-theme='default'] {
  .ant-tooltip-inner {
    background-color: $kairos-dark;
    a {
      color: #fff !important;
    }
    border-radius: 5px;
  }

  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: #0f2e4f;
  }
}
