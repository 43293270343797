// Import mixins
@import 'components/mixins.scss';

[data-kit-theme='default'] {
  .ant-input {
    &:hover,
    &:focus,
    &:active {
      border-color: $primary;
    }
  }

  .ant-input-affix-wrapper {
    &:hover,
    &:focus,
    &:active {
      &:not(.ant-input-affix-wrapper-disabled) {
        border-color: $primary;
      }
      .ant-input:not(.ant-input-disabled) {
        border-color: $kairos-dark;
      }
    }

    &-focused {
      box-shadow: 0 0 0 2px lighten($kairos-dark, 50%);
      // border-color: $kairos-dark;
    }
  }

  .task-input {
    .ant-input.ant-input-lg {
      height: 26px;
    }
  }

  .task-title {
    .ant-input.ant-input-lg {
      height: 45px;
      font-size: 26px;
      line-height: 38px;
      font-weight: 400;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-input {
    &-number {
      &-handler {
        &-wrap {
          background: $dark-gray-3;
        }
      }
    }
  }
}
