// Import mixins
@import 'components/mixins.scss';

[data-kit-theme='default'] {
  .ant-steps-dot .ant-steps-item-tail::after,
  .ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
    width: calc(100% - 30px);
    margin-left: 23px;
    margin-top: 6px;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #00925e;
  }

  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
    height: calc(100% - 5px);
    margin-left: 21px;
    margin-top: 10px;
  }

  .ant-steps-vertical .ant-steps-item-content {
    padding-left: 5px;
  }
}
