// Import mixins
@import 'components/mixins.scss';

[data-kit-theme='default'] {
  .ant-image-preview-mask {
    background-color: rgba(255, 255, 255, 1);
  }

  .ant-image-preview-operations {
    background-color: rgba(255, 255, 255, 1);
  }

  .ant-image-preview-operations-operation {
    color: $text;
  }
}
